import createSagaMiddleware from "redux-saga";
import Register from "./register";

const ApplySaga = () => {
  const sagaRegister = new Register();
  const middleware = createSagaMiddleware();

  //动态运行sagaeffects
  sagaRegister.setChangeListener((saga) => {
    middleware.run(saga);
  });

  return {
    middleware: middleware,
    register: (param) => sagaRegister.register(param),
    currentSagas: () => sagaRegister.getSagas(),
  };
};

export default ApplySaga;
