import { IActionTypeItem, IActionType } from "../../type";

const WrapperEffects = function (effects) {
  const _output = {};
  Array.from(effects).forEach((item: any) => {
    for (const key in item) {
      _output[key] = item[key];
    }
  });

  return _output;
};

export default (ActionType: IActionType, Service) => {
  if (!Service) {
    console.error("ajax service is not defined.");
    return;
  }
  const effects = Object.keys(ActionType).map((item) => {
    let _effects = {};

    let _effectGenerator = function* ({ data, call, callback, put, type }) {
      // console.log("effect", item)
      const _service = Service[item];
      if (!_service) {
        console.error("saga [" + item + "]'s service is not defined");
        return null;
      }

      const res = yield call(_service, data);

      // console.log(_service, res, 'service', item)
      if (callback) {
        callback(res);
      }

      if (res) {
        yield put({
          type: item + "@@auto",
          data: res,
        });
      }

      return res;
    };

    if (
      typeof ActionType[item] === "object" &&
      !!(ActionType[item] as IActionTypeItem).sagaOption
    ) {
      _effects = {
        [item]: {
          effect: _effectGenerator,
          option: (ActionType[item] as IActionTypeItem).sagaOption,
        },
      };
    } else {
      _effects = {
        [item]: _effectGenerator,
      };
    }

    return _effects;
  });

  return WrapperEffects(effects);
};
