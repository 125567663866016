import React from "react";
import { PaginationProps } from "antd";

export const getPaginationInfo = (props:PaginationProps): PaginationProps => {
	return {
		showQuickJumper:true,
		showSizeChanger:true,
		showTotal:(total:any,range:any):JSX.Element=>{
			return <>第 {range[0]===range[1]?range[0]:range.join("-")} 条  / 共 {total} 条  </>
		},
		...props
	}
}