import { IServiceActionType, IRequest } from "../../type";
import RequestMethod, { Service } from "../request";

export default <T = any>(ActionType: IServiceActionType, options?: any): T => {
  let _request = {} as any;

  //配置axios的config
  if (options?.initService) {
    options.initService(Service);
  }

  Object.keys(ActionType || {}).forEach((method) => {
    Object.keys(ActionType[method]).forEach((item) => {
      const _options: string | object | null = ActionType[method][item] as
        | string
        | object
        | null;

      if (typeof _options === "string") {
        _request[item] = function (data) {
          return RequestMethod[method]({
            url: _options,
            data: data || null,
          });
        };
      } else {
        _request[item] = function (data) {
          return RequestMethod[method]({
            ...(_options || {}),
            data: data || null,
          });
        };
      }
    });
  });

  // console.log(_request);

  return _request;
};
