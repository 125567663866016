import { createStore, applyMiddleware } from "redux";
import ReducerInstance from "./reducers";
import ApplySaga from "./saga";
import { IResult, IStoreOption, IStoreResult } from "../type";
export { default as GenerateEffect } from "./saga/generate";
export { default as GenerateService } from "./utils/service";
export { Service } from "./request";

export type {
  IActionType,
  IServiceActionType,
  IResult,
  IRequest,
  IStoreOption,
} from "../type";

const initStore = (option?: IStoreOption): IStoreResult => {
  let _middleware = [];
  let _initState = option?.initState || {};

  const Reducer = ReducerInstance(option?.reducer);
  const _reducer = Reducer.init;

  let _result: IStoreResult = {
    reducerInstance: Reducer.registerInstance, //注册reducer的方法
  };

  const _sagaInstance = ApplySaga();
  _result = { ..._result, sagaInstance: _sagaInstance };
  _middleware.push(_sagaInstance.middleware);

  let _storeInstance = null;

  if (_middleware.length > 0)
    _storeInstance = createStore(
      _reducer,
      _initState,
      applyMiddleware(..._middleware)
    );
  else _storeInstance = createStore(_reducer, _initState);

  Reducer.registerInstance.setChangeListener(_storeInstance);

  const dispatchAsync = (option) => {
    const { callback, ...restOption } = option;

    return new Promise((resolve, reject) => {
      _storeInstance.dispatch({
        ...restOption,
        callback: (res) => {
          if (callback) callback(res);
          resolve(res);
        },
      });
    });
  };
  
  return {
    ..._result,
    ..._storeInstance,
    store: _storeInstance,
    dispatchAsync,
  };
};

export { initStore };
