
/** 
 * @functionName useTableHeight
 * @param val-底部预留高度
 * @return {Number} tableHeight 计算后需要设置的表格高度
 * @description: 初始化页面计算表格高度、当页面大小发生变化时，改变表格高度
 */
import { useState, useEffect } from 'react';

const useTableHeight = (val)=> {
  const [tableHeight, setTableHeight] = useState('auto')
  
  useEffect(() => {
    // 计算表格的高度
    let tableHeight = 'auto';
    function handleCalcTableHeight() {
      if( document.querySelector(".ant-table") ){
        // 参数：窗口高度
        tableHeight = window.innerHeight - document.querySelector(".ant-table").getBoundingClientRect().top - (val ? val : (10 + 56 + 59));
      } else {
        tableHeight = 'auto';
      }
      setTableHeight(tableHeight);
    }
    // 页面加载完成立即执行一次
    handleCalcTableHeight();
    // 监听
    window.addEventListener("resize", handleCalcTableHeight);
    // 销毁
    return ()=>window.removeEventListener("resize", handleCalcTableHeight) ;
  });
  return tableHeight;
}

export default useTableHeight;