import { combineReducers } from "redux";

class IReducerRegistry {
  _reducers: Object;
  _emitChange: Function | null;
}

export const initReducer = (reducer) => {
  if (!!reducer) return reducer;

  return (state) => {
    return state;
  };
};

const baseReducer = (state) => {
  return {
    login: false,
  };
};

const createReducer = (reducers) => {
  return combineReducers({
    init: baseReducer,
    ...reducers,
  });
};

class ReducerRegistry extends IReducerRegistry {
  constructor(defaultReducer) {
    super();
    this._emitChange = null;
    this._reducers = defaultReducer || {};
  }

  getReducers() {
    return { ...this._reducers };
  }
  register(reducers) {
    //注册reducer到store中
    //reducers key&value
    if (!!reducers && !!this._emitChange) {
      this._reducers = { ...this._reducers, ...reducers };
      !!this._emitChange && this._emitChange();
    }
  }

  setChangeListener(store) {
    this._emitChange = () => {
      if (!store) return;

      store.replaceReducer(createReducer(this.getReducers()));
    };
  }
}

export default ReducerRegistry;
