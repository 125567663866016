import React, {
  useImperativeHandle,
  useContext,
  useEffect,
  useMemo,
  useState,
  useRef,
} from "react";

import CustomTable from "../common";
import TableContext, { TableProvider, useTable } from "./context";
import TableLayout from "../Layout";
import { IRemoteInternalTableProps } from "../../types";
import { FilterForm } from "../../../../packages/FormComponent/src";
import "../index.scss";

const SearchTable: React.FC<IRemoteInternalTableProps> = React.forwardRef(
  (props: IRemoteInternalTableProps, ref) => {
    const tableContext = useContext(TableContext);

    const { loadData, table, ...restProps } = props;
    const [tableInstance] = useTable(table);

    //@ts-ignore
    tableInstance.setCallbacks({
      loadData: loadData,
    });

    useEffect(() => {
      tableContext.applyContext(tableInstance);
    }, [tableContext, tableInstance]);

    //@ts-ignore
    const {
      loading,
      dataSource,
      pagination,
      defaultPagination,
    } = tableInstance.getInternalInstance();

    useImperativeHandle(ref, () => tableInstance);

    useEffect(() => {
      tableInstance.load();
    }, []);

    const onChange = (params, filters, sorter, extra) => {
      const { pageSize, current } = params;

      if (extra.action === "sort") {
        tableInstance.sortTable(
          !sorter.order
            ? null
            : {
                sorter: {
                  column: sorter.field,
                  orderType: sorter.order,
                },
              }
        );
      } else tableInstance.updatePageInfo({ pageSize, current });
    };

    return (
      <CustomTable
        loading={loading}
        dataSource={dataSource}
        defaultPagination={defaultPagination}
        pagination={pagination}
        onChange={onChange}
        {...restProps}
      ></CustomTable>
    );
  }
);

const SearchTableProvider: React.FC<IRemoteInternalTableProps> = React.forwardRef(
  (props: IRemoteInternalTableProps, ref: React.Ref<any>) => {
    const {
      table,
      tableTitle,
      header: Header,
      showColumnControl,
      columns,
      columnSettiongIcon,
      actionBar,
      extraFilterItems,
      ...restProps
    } = props;

    const [tableInstance] = useTable(table);

    const [showColumns, setColumns] = useState(columns);

    const onColumnChange = (params) => {
      setColumns(
        columns.filter((item: any) => params.indexOf(item.dataIndex) >= 0)
      );
    };

    const [filterFormItems, setFormItems] = useState([]);

    useEffect(() => {
      let _filterColumns = columns?.filter((item: any) => item.filter);
      let _filterItems = [];
      if (_filterColumns?.length > 0) {
        // let _idx = 0;

        // //组合排序信息
        // _filterColumns = _filterColumns.map((item: any) => {
        //   if (!!item.filterOrderIndex) {
        //     return item;
        //   } else {
        //     item.filterOrderIndex = _idx;
        //     _idx++;

        //     return item;
        //   }
        // });

        // _filterColumns = _filterColumns.sort(
        //   (a: any, b: any) => a.filterOrderIndex - b.filterOrderIndex
        // );

        _filterItems =
          _filterColumns.map(function (item: any) {
            if (!!item.filterFormItem) {
              return item.filterFormItem;
            }
            return {
              type: item.type ?? "input",
              sortIndex: item.sortIndex || 0,
              formItemProps: {
                label: item.title,
                name: item.dataIndex,
                ...(item.formItemProps ?? {}),
              },
              formControlProps: {
                allowClear: true,
                ...(item.formControlProps ?? {}),
              },
            };
          }) ?? [];
      }

      _filterItems = [...(extraFilterItems ?? []),...(_filterItems ?? [])];

      if (_filterItems.length > 0) {
        setFormItems(_filterItems.sort((a, b) => a.sortIndex - b.sortIndex));
      }

      setColumns(columns);
    }, [columns]);

    //搜索按钮
    const onSearch = (values) => {
      if (props.onSearch) {
        props.onSearch(values, tableInstance);
      } else tableInstance.search(values);
    };

    const filterFormRef = useRef();

    useImperativeHandle(ref, () => ({
      ...tableInstance,
    }));

    useEffect(() => {
      tableInstance
        .getInternalInstance()
        ?.setFilterForm?.(filterFormRef.current);
    }, [filterFormRef, filterFormItems]);

    return (
      <>
        {filterFormItems.length > 0 && (
          <div className="filter-form">
            <FilterForm
              ref={filterFormRef}
              formItems={filterFormItems ?? []}
              onSearch={onSearch}
              onReset={() => tableInstance.reset()}
              expandable={filterFormItems.length > 8}
              actionButtons={props.actionButtons}
            ></FilterForm>
          </div>
        )}

        <TableLayout
          title={tableTitle}
          header={Header}
          columns={columns}
          onColumnChange={onColumnChange}
          columnSettiongIcon={columnSettiongIcon}
          showColumnControl={showColumnControl}
          actionBar={
            typeof actionBar === "function"
              ? (props) =>
                  actionBar?.({ form: filterFormRef.current, ...(props || {}) })
              : actionBar ?? <></>
          }
        >
          <TableProvider>
            <SearchTable
              table={tableInstance}
              setSelectedRows={(rows) => tableInstance.setSelectedRows(rows)}
              bordered={true}
              columns={showColumns}
              {...restProps}
            />
          </TableProvider>
        </TableLayout>
      </>
    );
  }
);

//@ts-ignore
SearchTableProvider.useTable = useTable;

export default SearchTableProvider;
