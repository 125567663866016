import { Button, Form, Table } from "antd";
import type { FormInstance } from "antd/es/form";
import React, { useContext, useEffect, useRef, useState } from "react";
import getFormControl from "../../../FormComponent/src/common/controls/formControl";
import "./index.scss";

const EditableContext = React.createContext<FormInstance<any> | null>(null);

interface EditableRowProps {
  index: number;
}

const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: keyof any;
  record: any;
  handleSave: (record: any) => void;
}

const EditableCell: React.FC<EditableCellProps> = ({
  title,
  type,
  rules,
  options,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const form = useContext(EditableContext)!;

  useEffect(() => {
    // 数据回显
    form.setFieldsValue({ ...record });
  }, [record]);

  const save = async () => {
    try {
      const values = await form.validateFields();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };

  let childNode = children;

  // 编辑状态表格内容
  if (editable) {
    childNode = (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        rules={
          rules
            ? rules
            : [
                {
                  required: type === "switch" ? false : true,
                  message:
                    type === "input" ? `请输入${title}` : `请选择${title}`,
                },
              ]
        }
      >
        {getFormControl({
          type,
          formControlProps: { onBlur: save, options },
          ...restProps,
        })}
      </Form.Item>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

type EditableTableProps = Parameters<typeof Table>[0];

type ColumnTypes = Exclude<EditableTableProps["columns"], undefined>;

const EditTable: React.FC = (props) => {
  // const form = Form.useFormInstance();
  const { tableColumns = [], isDelete = true, ...resProps } = props || {};

  let dataObj = {};
  tableColumns.forEach((item) => {
    dataObj[item.dataIndex] = null;
  });

  const [count, setCount] = useState(2);

  const [dataSource, setDataSource] = useState<any[]>([{ key: 1, ...dataObj }]);

  const defaultColumns: (ColumnTypes[number] & {
    editable?: boolean;
    dataIndex: string;
  })[] = [
    {
      title: "操作",
      width: "100px",
      dataIndex: "operation",
      render: (_, record: { key: React.Key }) =>
        dataSource.length >= 1 ? (
          <a onClick={() => handleDelete(record.key)}>删除</a>
        ) : null,
    },
  ];

  const handleDelete = (key: React.Key) => {
    const newData = dataSource.filter((item) => item.key !== key);
    setDataSource(newData);
  };

  const handleAdd = () => {
    const newData: any = {
      key: count,
      ...dataObj,
    };
    setDataSource([...dataSource, newData]);
    setCount(count + 1);
  };

  const handleSave = (row: any) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    // console.log(newData);
    setDataSource(newData);
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const columns = [
    ...tableColumns.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record: any) => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          type: col.type,
          handleSave,
          ...col,
        }),
      };
    }),
    ...(isDelete ? defaultColumns : []),
  ];

  useEffect(() => {
    props?.onChange?.(dataSource);
  }, [dataSource]);

  return (
    <div className="edit-table">
      <Button onClick={handleAdd} type="link" style={{ marginBottom: 16 }}>
        新增
      </Button>
      <Table
        components={components}
        rowClassName={() => "editable-row"}
        bordered
        dataSource={dataSource}
        columns={columns as ColumnTypes}
        pagination={false}
      />
    </div>
  );
};

export default EditTable;
