import axios, { AxiosRequestConfig, AxiosRequestHeaders } from "axios";

let domainUrl: string = "";

export const requestDomainUrl = "";

const Service = axios.create({
  timeout: 10000,
  withCredentials: true,
  transformRequest: [
    function (data, headers: AxiosRequestHeaders | undefined) {
      if (!!data && !!headers) {
        if (headers["Content-Type"] === "application/x-www-form-urlencoded") {
          let _data = new FormData();
          Object.keys(data).forEach((key, value) => {
            _data.append(key, data[key]);
          });
          return _data;
        }
        return JSON.stringify(data);
      }
      return data;
    },
  ],
  transformResponse: [
    function (data) {
      try {
        return JSON.parse(data);
      } catch (ex) {
        return data;
      }
    },
  ],
});

type IAxiosParam = AxiosRequestConfig & {
  restful?: string[];
};

const PostMethod = (param: IAxiosParam) => {

  let { data, restful, url } = param;
  if (!!restful) {
    restful.forEach((item) => {
      url = url.replace(`{${item}}`, data?.[item]);

      delete data?.[item];
    });
  }
  // console.log("requestDomainUrl",requestDomainUrl,"---",url)
  let _obj: AxiosRequestConfig = {
    baseURL: requestDomainUrl,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    ...(param ?? {
      data: {},
    }),
    url,
  };

  return Service(_obj);
};

const GetRequest = (param?: IAxiosParam) => {
  let { data, restful, url, ...restParam } = param;

  if (!!restful) {
    restful.forEach((item) => {
      url = url.replace(`{${item}}`, data?.[item]);

      delete data?.[item];
    });
  }

  let _obj: AxiosRequestConfig = {
    baseURL: requestDomainUrl,
    method: "get",
    url,
    params: data || {},
    ...(restParam ?? {}),
  };

  return Service(_obj);
};

export { Service };

export default {
  Get: GetRequest,
  Post: PostMethod,
};
