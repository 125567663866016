import React, { useState, useRef } from "react";
import {
  IPaginationInfo,
  ITableContext,
  ITableCallbackParam,
  ILoadDataParam,
} from "../../types";

const TableContext = React.createContext<ITableContext>(null);

const _defaultPagination = {
  current: 1,
  pageSize: 15,
  total: 0,
};

interface ITableStore {
  forceRender: () => void;
  loading: boolean;
  dataSource: Array<any>;
  pagination: IPaginationInfo | null;
}

class TableStore {
  forceRender: Function;
  loading: boolean;
  dataSource: Array<any>;
  defaultPagination: {
    current: 1;
    pageSize: 15;
    total: 0;
  };
  sorter: null;
  pagination: IPaginationInfo | null;
  filterForm: any;
  searchParams: Object | null;
  loadData: (params: ILoadDataParam, callback?: Function) => void;
  selectedRows: [];
  private callbacks = {};
  constructor(forceupdate) {
    this.forceRender = forceupdate;
  }
  load = () => {
    //加载内容
    //@ts-ignore
    const { loadData } = this.callbacks;
    this.loading = true;
    // debugger;
    if (!loadData) {
      this.loading = false;
    }

    loadData?.(
      {
        pagination: { ..._defaultPagination, ...(this.pagination || {}) },
        searchParams: this.searchParams || {},
        sorter: this.sorter || {},
      },
      (cb: ITableCallbackParam) => {
        // debugger;
        const { data = [], pagination = _defaultPagination } =
          cb ?? ({} as ITableCallbackParam);
        this.loading = false;
        this.dataSource = data;
        this.pagination = pagination;
        this.forceRender();
      }
    );

    this.forceRender();
  };
  reload = () => {
    this.load();
  };
  reset = () => {
    this.pagination = null;
    this.searchParams = null;
    this.load();
  };
  setFilterForm = (_form) => {
    this.filterForm = _form;
  };
  getFilterFormInstance = () => {
    return this.filterForm;
  };
  search = (params) => {
    //搜索
    this.searchParams = params;
    this.pagination = _defaultPagination;
    this.load();
  };
  sortTable = (params) => {
    this.sorter = params;
    this.pagination = _defaultPagination;
    this.load();
  };
  setCallbacks = (params) => {
    //设置部分初始内容
    this.callbacks = params;
  };
  updatePageInfo = (params) => {
    this.pagination = params;
    this.load();
  };
  setSelectedRows = (rows) => {
    this.selectedRows = rows;
  };
  getLatestParams = () => {
    return {
      pagination: this.pagination || _defaultPagination,
      searchParams: this.searchParams,
      sorter: this.sorter,
    };
  };
  getSelectedRows = () => {
    return this.selectedRows;
  };
  getData = () => {
    return {
      paginationInfo: this.pagination,
      data: this.dataSource,
    };
  };
  clearSortInfo = () => {};
  getInstance = () => {
    //获取实例
    return {
      search: this.search,
      reload: this.reload,
      load: this.load,
      reset: this.reset,
      setCallbacks: this.setCallbacks,
      updatePageInfo: this.updatePageInfo,
      sortTable: this.sortTable,
      getLatestParams: this.getLatestParams,
      getInternalInstance: this.getInternalInstance,
      getSelectedRows: this.getSelectedRows,
      setSelectedRows: this.setSelectedRows,
      getData: this.getData,
      filterForm: this.getFilterFormInstance,
    };
  };
  getInternalInstance = () => {
    return {
      search: this.search,
      reload: this.reload,
      loading: this.loading,
      dataSource: this.dataSource,
      pagination: this.pagination,
      defaultPagination: _defaultPagination,
      setCallbacks: this.setCallbacks,
      setSelectedRows: this.setSelectedRows,
      setFilterForm: this.setFilterForm,
    };
  };
}

const useTable = (tableRef: any) => {
  const ref = useRef<any>();
  const [, forceUpdate] = useState({});

  if (!ref.current) {
    if (tableRef) {
      ref.current = tableRef;
    } else {
      const _tableStore = new TableStore(() => forceUpdate({}));

      ref.current = _tableStore.getInstance();
    }
  }

  return [ref.current];
};

const TableProvider = ({ children }) => {
  const ref = useRef();

  return (
    <TableContext.Provider
      value={{
        applyContext: (context) => {
          ref.current = context;
        },
      }}
    >
      {children}
    </TableContext.Provider>
  );
};

export { useTable, TableProvider };

export default TableContext;
